import { template as template_e1c0aad77b7d403984bc22cd740761ce } from "@ember/template-compiler";
const FKLabel = template_e1c0aad77b7d403984bc22cd740761ce(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
