import { template as template_6de300212e0a42659afadbac62aff484 } from "@ember/template-compiler";
const WelcomeHeader = template_6de300212e0a42659afadbac62aff484(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
