import { template as template_6191e26edb664f60ae0dbe3b0235c737 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6191e26edb664f60ae0dbe3b0235c737(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
